import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'

import './baseTable.css'

const PaginatedBaseTable = ({
  headers,
  children,
  rowsPerPage,
  tableName,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.ceil(children.length / rowsPerPage)
  const currentData = children.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)

  const renderHead = () => {
    return headers.map(header => (
      <th key={header} className={`${tableName}__header-column ${tableName}__${kebabCase(header)}`}>
        {header}
      </th>
    ))
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  return (
    <div className='table-container'>
      <table className={`${tableName} base-table`}>
        <thead className={`${tableName}__table-head`}>
          <tr>{renderHead()}</tr>
        </thead>
        <tbody>{currentData}</tbody>
      </table>

      <div className='pagination-controls'>
        <button
          name='previous'
          onClick={handlePrevious}
          className={currentPage === 1 ? 'hidden' : ''}
        >
          Previous
        </button>
        <span className='page-info'>
          Page {currentPage} of {totalPages}
        </span>
        <button
          name='next'
          onClick={handleNext}
          className={currentPage === totalPages ? 'hidden' : ''}
        >
          Next
        </button>
      </div>
    </div>
  )
}

PaginatedBaseTable.defaultProps = {
  tableName: 'paginated-base-table',
  rowsPerPage: 20, // default 20 lines per page
}

PaginatedBaseTable.propTypes = {
  tableName: PropTypes.string,
  headers: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
}

export default PaginatedBaseTable
