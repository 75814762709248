import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'

import './baseTable.css'

const BaseTable = props => {
  const renderHead = () => {
    const tableName = props.tableName

    return props.headers.map(header => (
      <th key={header} className={`${tableName}__header-column ${tableName}__${kebabCase(header)}`}>
        {header}
      </th>
    ))
  }

  const selectAllCheckbox = () => {
    return (
      <th>
        <input type='checkbox' onChange={props.onSelectAll} checked={props.selectAllActive} />
      </th>
    )
  }

  return (
    <table className={`${props.tableName} base-table`}>
      <thead className={`${props.tableName}__table-head`}>
        <tr>
          {props.onSelectAll && selectAllCheckbox()}
          {renderHead()}
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  )
}

BaseTable.defaultProps = {
  tableName: 'base-table',
  selectAllActive: false,
}

BaseTable.propTypes = {
  tableName: PropTypes.string,
  headers: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired,
  onSelectAll: PropTypes.func,
  selectAllActive: PropTypes.bool,
}

export default BaseTable
