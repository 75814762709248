import React from 'react'
import PropTypes from 'prop-types'
import Filter from 'components/shared/filter/filter'

function ShippingLabelsFilters(props) {
  const [availableFilters, setAvailableFilters] = React.useState({
    shipper: [],
  })

  React.useEffect(() => {
    let shippers = new Set()

    if (props.data) {
      props.data.forEach(label => {
        shippers.add(label.shipper)
      })
    }

    setAvailableFilters({
      shipper: Array.from(shippers),
    })
  }, [props.data])

  const updateShipperFilter = e => {
    const filterValue = e.target.value
    let newActiveFilters = props.activeFilters

    if (filterValue === 'all') {
      delete newActiveFilters['shipper']
    } else {
      newActiveFilters['shipper'] = filterValue
    }

    return props.onFiltersUpdate(newActiveFilters)
  }

  const handleSearchInput = e => {
    const filterValue = e.target.value
    let newActiveFilters = props.activeFilters
    if (filterValue) {
      newActiveFilters['shipmentNumber'] = filterValue
    } else {
      delete newActiveFilters['shipmentNumber']
    }
    return props.onFiltersUpdate(newActiveFilters)
  }

  if (props.data && props.data.length > 0) {
    return (
      <div className='shipping-labels-filters'>
        <Filter
          name='shipper'
          displayName='Shipper'
          defaultText='All Shippers'
          selectedValue={props.activeFilters.shipper}
          onChange={updateShipperFilter}
          data={availableFilters.shipper}
        />
        <input
          className='filter__shipment_number'
          name='shipment_number'
          placeholder='Shipment number'
          type='search'
          onChange={handleSearchInput}
        />
        {props.children}
      </div>
    )
  }

  return null
}

ShippingLabelsFilters.propTypes = {
  onFiltersUpdate: PropTypes.func.isRequired,
  activeFilters: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      shipmentNumber: PropTypes.string.isRequired,
      shipmentDate: PropTypes.string.isRequired,
      deliveryDate: PropTypes.string.isRequired,
      shipper: PropTypes.string.isRequired,
      trackingNumber: PropTypes.string,
    }),
  ),
  children: PropTypes.array,
}

export default ShippingLabelsFilters
