import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import FlashMessage from '../flashMessage/flashMessage'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'
import FirstDeliveryWeekPicker from './firstDeliveryWeekPicker/firstDeliveryWeekPicker'

import './deliveryOptionsUpdate.css'
import { updateDeliveryOptionsCsvUpload } from 'actions/deliveryOptions'

import { showModal } from 'actions/modal'
import { DELIVERY_OPTIONS_UPDATE_PREVIEW_MODAL } from 'components/modal/modalTypes'

const INFO_MESSAGE_TEXT =
  'Updating delivery options is a replacement of an existing option with a new one. </br>' +
  'If the desired option does not exist yet, please create it first and then proceed with the ' +
  'replacement. <br/> Updating will move all orders (regular and special) and user plans to ' +
  'the new delivery option. Old delivery options will no longer be available.'
const REGION_TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/1kYl5VbS59il_3QWBrqIWB3Gralt1luBcLNnT7F29b6I'
const ZIPCODE_TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/1fQxrahEjQBInJmV86J7ieN8ji3zmZIu8YZa4d_AsRnw'

export class DeliveryOptionsUpdate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFirstDeliveryWeek: null,
      showMessage: false,
    }
  }

  componentDidMount() {
    this.setState({ showMessage: false })
  }

  componentDidUpdate(prevProps) {
    if (this.props.csvContent !== prevProps.csvContent) {
      this.setState({ selectedFirstDeliveryWeek: null })
    }

    if (this.props.responseBody !== prevProps.responseBody) {
      this.setState({ showMessage: !!this.props.responseBody })
    }
  }

  hideMessage = () => {
    this.setState({ showMessage: false })
  }

  setFirstDeliveryWeek = selectedFirstDeliveryWeek => {
    this.setState({ selectedFirstDeliveryWeek })
  }

  onFileUpload = csvData => {
    this.props.dispatch(updateDeliveryOptionsCsvUpload(csvData))
  }

  openPreviewModal = () => {
    this.props.dispatch(
      showModal(DELIVERY_OPTIONS_UPDATE_PREVIEW_MODAL, {
        firstDeliveryWeek: this.state.selectedFirstDeliveryWeek,
      }),
    )
  }

  isBtnDisabled() {
    const { country, brand, csvContent } = this.props
    return !country || !brand || !csvContent || !this.state.selectedFirstDeliveryWeek
  }

  render() {
    if (this.props.isLoading) {
      return <div className='delivery-options-update__loader'>Loading...</div>
    } else if (this.props.canManage) {
      return (
        <div className='delivery-options-update'>
          <FlashMessage
            showMessage={this.state.showMessage}
            message={(this.props.responseBody && this.props.responseBody.message) || ''}
            errors={(this.props.responseBody && this.props.responseBody.errors) || []}
            onClose={this.hideMessage}
          />

          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <BrandFilter />
          <InfoMessage message={INFO_MESSAGE_TEXT} />

          <CsvUploader onFileUpload={this.onFileUpload} />

          <ZiplogLink
            href={REGION_TEMPLATE_LINK}
            className='delivery-options-update__ziplog-link'
            text='CSV Template by Region'
            openInNewTab
          />
          <ZiplogLink
            href={ZIPCODE_TEMPLATE_LINK}
            className='delivery-options-update__ziplog-link'
            text='CSV Template by Zipcode'
            openInNewTab
          />

          <FirstDeliveryWeekPicker
            selectedFirstDeliveryWeek={this.state.selectedFirstDeliveryWeek}
            onChange={this.setFirstDeliveryWeek}
          />

          <button
            className='delivery-options-update__save-button button-primary'
            disabled={this.isBtnDisabled()}
            onClick={this.openPreviewModal}
          >
            Save
          </button>
        </div>
      )
    }

    return null
  }
}

DeliveryOptionsUpdate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  responseBody: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.deliveryOptionsUpdate.csvContent,
    isLoading: state.deliveryOptionsUpdate.isLoading,
    responseBody: state.deliveryOptionsUpdate.responseBody,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedDeliveryOptionsUpdate = connect(mapStateToProps)(DeliveryOptionsUpdate)

export default connectedDeliveryOptionsUpdate
