import superagent from 'superagent'

export const shippingLabelApi = {
  getShippingLabel,
  generateShippingLabel,
  bulkGenerateShippingLabel,
}

function getShippingLabel(shipmentNumber, featureFlags = []) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/shipping_labels/${shipmentNumber}`
  const logisticsToken = localStorage.getItem('logisticsToken')
  const params = featureFlags.length > 0 ? { feature_flags: featureFlags.join(',') } : {}

  return superagent
    .get(url)
    .query(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

function generateShippingLabel(shipmentNumber, country) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/shipping_labels/${shipmentNumber}/generate`
  const logisticsToken = localStorage.getItem('logisticsToken')
  const params = { country: country }

  return superagent
    .post(url)
    .query(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

function bulkGenerateShippingLabel(shipmentsNumber, country) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/shipping_labels/bulk_generate`
  const logisticsToken = localStorage.getItem('logisticsToken')
  const params = { country, shipments_reference: shipmentsNumber }

  return superagent
    .post(url)
    .send(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
