// Nested parentheses don't work with React Router regex matching.
// DO NOT use any regex groups with parentheses here!!
// Using the `|` operator is preferred

// Issue: https://github.com/pillarjs/path-to-regexp/issues/95#issuecomment-400725757
// Tester: http://forbeslindesay.github.io/express-route-tester/

export default function shipmentNumberRegex() {
  const msRegex = '[A-Z]{2}-R[0-9]*-[0-9]{2}'
  const dnRegex = '[A-Z]{2}-[A-Z]{2}-R[0-9]*-[0-9]{2}'
  const oobRegex = '[A-Z]{2}-[A-Z]{1,2}[0-9]*-[0-9]{2}'
  return `(${dnRegex}|${msRegex}|${oobRegex})`
}
