import React from 'react'
import PropTypes from 'prop-types'

import formatDate from 'helpers/formatDate'
import PaginatedZiplogTable from 'components/shared/ziplogTable/paginatedZiplogTable'
import ViewLabelLink from './viewLabelLink/viewLabelLink'
import OrderPageLink from 'components/shared/orderPageLink/orderPageLink'

import { STATUSES } from 'helpers/constants'

import './shippingLabelsTable.css'

function ShippingLabelsTable(props) {
  const fields = [
    'shipmentNumber',
    'shipmentDate',
    'deliveryDate',
    'shipper',
    'trackingNumber',
    'viewLabel',
  ]

  const rowData = () => {
    return props.data.map(data => ({
      shipmentNumber: <OrderPageLink shipmentNumber={data['shipmentNumber']} />,
      shipmentDate: formatDate(data['shipmentDate']),
      deliveryDate: formatDate(data['deliveryDate']),
      shipper: data['shipper'],
      trackingNumber: data['trackingNumber'],
      viewLabel: (
        <ViewLabelLink shipmentNumber={data['shipmentNumber']} featureFlags={props.featureFlags} />
      ),
    }))
  }

  const isEmpty = data => {
    return data && data.length === 0
  }

  const displayNoResults = () => {
    return props.data && isEmpty(props.data)
  }

  if (props.status === STATUSES.requested) {
    return <div className='shipping-labels-table__loader'>Loading...</div>
  } else if (props.data === null) {
    return false
  } else if (displayNoResults()) {
    return <div className='shipping-labels-table__no-results-msg'>No shipping labels found.</div>
  } else {
    return (
      <PaginatedZiplogTable
        tableName='shipping-labels-table'
        headers={fields}
        data={rowData()}
        columnRenames={{
          shipmentNumber: 'Shipment #',
          trackingNumber: 'Tracking #',
          viewLabel: '',
        }}
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
      />
    )
  }
}

ShippingLabelsTable.propTypes = {
  status: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      shipmentNumber: PropTypes.string.isRequired,
      shipmentDate: PropTypes.string.isRequired,
      deliveryDate: PropTypes.string.isRequired,
      shipper: PropTypes.string.isRequired,
      trackingNumber: PropTypes.string,
    }),
  ),
  featureFlags: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
}

export default ShippingLabelsTable
