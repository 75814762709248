import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

import { showModal } from 'actions/modal'
import { DELIVERY_OPTIONS_NEW_PREVIEW_MODAL } from 'components/modal/modalTypes'

import './deliveryOptionsNew.css'
import { createDeliveryOptionsCsvUpload } from 'actions/deliveryOptions'
import FlashMessage from 'components/flashMessage/flashMessage'

const INFO_MESSAGE_TEXT =
  'Delivery Options will be available immediately for all new users ' +
  'and existing users editing their orders.'
const REGION_TEMPLATE_LINK =
  'https://drive.google.com/open?id=1AtrpuBeRlUtmBvyfe_wmTDfSeMT9P61Ogd8b8rxVFAk'
const ZIPCODE_TEMPLATE_LINK =
  'https://drive.google.com/open?id=1f1rrR_bW64qQfDD1Pqmu1g-NIaBszPSNmiHv3Let7nE'

export class DeliveryOptionsNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showMessage: false,
    }
  }

  openPreviewModal = () => {
    this.props.dispatch(showModal(DELIVERY_OPTIONS_NEW_PREVIEW_MODAL))
  }

  onFileUpload = csvData => {
    this.props.dispatch(createDeliveryOptionsCsvUpload(csvData))
  }

  isBtnDisabled() {
    const { country, brand, csvContent } = this.props
    return !country || !brand || !csvContent
  }

  componentDidMount() {
    this.setState({ showMessage: false })
  }

  componentDidUpdate(prevProps) {
    if (this.props.responseBody && this.props.responseBody !== prevProps.responseBody) {
      this.setState({ showMessage: !!this.props.responseBody })
    }
  }

  hideMessage = () => {
    this.setState({ showMessage: false })
  }

  render() {
    if (this.props.isLoading) {
      return <div className='delivery-options-new__loader'>Loading...</div>
    } else if (this.props.canManage) {
      return (
        <div className='delivery-options-new'>
          <FlashMessage
            showMessage={this.state.showMessage}
            message={this.props?.responseBody?.message || ''}
            errors={this.props?.responseBody?.errors || []}
            onClose={this.hideMessage}
            showNewDeliveryOption={false}
          />
          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <BrandFilter />
          <InfoMessage message={INFO_MESSAGE_TEXT} />

          <CsvUploader onFileUpload={this.onFileUpload} />

          <ZiplogLink
            href={REGION_TEMPLATE_LINK}
            className='delivery-options-new__ziplog-link'
            text='CSV Template by Region'
            openInNewTab
          />

          <ZiplogLink
            href={ZIPCODE_TEMPLATE_LINK}
            className='delivery-options-new__ziplog-link'
            text='CSV Template by Zipcode'
            openInNewTab
          />

          <button
            className='delivery-options-new__save-button button-primary'
            disabled={this.isBtnDisabled()}
            onClick={this.openPreviewModal}
          >
            Save
          </button>
        </div>
      )
    }

    return null
  }
}

DeliveryOptionsNew.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  responseBody: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.deliveryOptionsNew.csvContent,
    isLoading: state.deliveryOptionsNew.isLoading,
    responseBody: state.deliveryOptionsNew.responseBody,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedDeliveryOptionsNew = connect(mapStateToProps)(DeliveryOptionsNew)

export default connectedDeliveryOptionsNew
